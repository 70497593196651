/* Products Carousel */
.asio-products-carousel__container {
  @apply pb-[72px] m-auto w-full items-stretch flex overflow-x-auto scroll-smooth max-w-full;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.asio-products-carousel__container::-webkit-scrollbar {
  @apply invisible;
}

.asio-products-carousel__product {
  @apply flex w-[260px] md:w-[320px] shrink-0 mr-2 md:mr-4;
}

.asio-products-carousel__product:last-child {
  @apply mr-0;
}
