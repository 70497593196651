.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 28rem;
  padding: 1.6rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: contain;
}

.embla-thumbs {
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 7rem;
  margin-top: var(--thumbs-slide-spacing);
}
.embla-thumbs__viewport {
  overflow: hidden;
}
.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.embla-thumbs__slide {
  flex: 0 0 28%;
  min-width: 0;
  margin-left: calc(var(--thumbs-slide-spacing) - 8px);
  position: relative;
}
@media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 18%;
  }
}
.embla-thumbs__slide__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  transition: opacity 0.2s;
}
.embla-thumbs__slide--selected .embla-thumbs__slide__button {
  opacity: 1;
}
.embla-thumbs__slide__img {
  display: block;
  height: var(--thumbs-slide-height);
  width: 100%;
  object-fit: cover;
}
.embla-thumbs__slide__number {
  width: 3rem;
  height: 3rem;
  z-index: 1;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 3rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla-thumbs__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
